@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-block-start: 60px;
  margin-block-end: 60px;

  @media (--viewportMedium) {
    margin-block-start: 10vh;
  }
}

.number {
  composes: marketplaceHeroTitleFontStyles from global;
  text-align: center;
  color: var(--marketplaceColor);

  margin-block-start: 1px;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  text-align: center;
  margin-block-start: 16px;

  @media (--viewportMedium) {
    margin-block-start: 22px;
  }
}

.description {
  text-align: center;
  margin-block-end: 0;

  @media (--viewportMedium) {
    margin-block-start: 24px;
    margin-block-end: 0;
  }
}

.searchForm {
  max-width: 408px;

  margin-block-start: 62px;
  margin-inline-end: auto;
  margin-block-end: 0;
  margin-inline-start: auto;

  background-color: var(--colorWhite);
  border-block-end: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-block-start: 64px;
  }
}
