@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding-block-start: 5px;
    padding-inline-end: 0;
    padding-block-end: 3px;
    padding-inline-start: 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-inline-end: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
}
