.root {
}

.bookingInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateSection {
  margin-inline-end: 5px;
}
