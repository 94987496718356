@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-block-start: 6px;
  margin-block-end: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-block-start: 8px;
  }
}
