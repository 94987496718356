@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h6 from global;
  color: var(--colorWhite);
  text-align: center;

  background-color: var(--colorFail);

  /* Layout of a red dot */
  display: inline-block;
  width: auto;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-inline-start: 4px;

  padding-block-start: 1px;
  padding-inline-end: 5px;
  padding-block-end: 3px;
  padding-inline-start: 6px;

  /* Font margins off */
  margin-block-start: 0;
  margin-block-end: 0;

  @media (--viewportMedium) {
    margin-block-start: 0;
    margin-block-end: 0;

    padding-block-start: 2px;
    padding-inline-end: 5px;
    padding-block-end: 3px;
    padding-inline-start: 5px;
  }

  @media (--viewportLarge) {
    font-size: 14px;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    top: -2px;

    padding-block-start: 4px;
    padding-inline-end: 5px;
    padding-block-end: 4px;
    padding-inline-start: 5px;
    position: relative;
  }
}
