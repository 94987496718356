@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-inline-start: 16px;

  &:first-child {
    margin-inline-start: 0;
  }

  @media (--viewportLarge) {
    margin-inline-start: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorGrey700);
  padding-block-end: 10px;
  padding-block-start: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-block-start: auto;

  border-block-end-width: 2px;
  border-block-end-style: solid;
  border-block-end-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-block-end-width: 0px;
    margin-block-start: 0;
    margin-block-start: 16px;

    padding-block-start: 5px; /* Drop the text to adjust it to correct baseline */
    padding-block-end: 3px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}
[dir='rtl'] .link {
  background-position: calc(var(--TabNav_linkWidth) + 4px) center;
  @media (--viewportLarge) {
    &:hover {
      background-position: left center;
    }
  }
}

.selectedLink {
  border-block-end-color: var(--colorBlack);
  color: var(--colorBlack);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

/*[dir='rtl'] .selectedLink {*/
/*  background-position: left center;*/
/*  @media (--viewportLarge) {*/
/*    &:hover {*/
/*      background-position: left center;*/
/*    }*/
/*  }*/
/*}*/

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
