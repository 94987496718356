@import '../../styles/customMediaQueries.css';

.avatarBase {
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--colorWhite);

  &:hover {
    text-decoration: none;
  }
}

/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;
}

.initials {
  composes: textXSmall from global;
  padding: 0;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.smallAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 32px;
  height: 32px;
}
.initialsSmall {
  /* Exception and unique font-size for the small avatar */
  font-size: 13px;
  font-weight: var(--fontWeightBold);
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}
.initialsMedium {
  composes: textLarge from global;
  padding: 0;
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;
  padding: 0;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.initialsLarge {
  composes: h3 from global;
  padding: 0;
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
