@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */

    padding-block-start: 48px;
    padding-inline-end: 36px;
    padding-block-end: 82px;
    padding-inline-start: 59px;

    background-color: var(--colorWhite);
    border-inline-start-width: 1px;
    border-inline-start-style: solid;
    border-inline-start-color: var(--colorGrey100);
    border-inline-start-width: 1px;
    border-inline-start-style: solid;
    border-inline-start-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding-block-start: 48px;
    padding-inline-end: calc((100% - 1056px) / 2);
    padding-block-end: 82px;
    padding-inline-start: 59px;
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  border-block-start-width: 1px;
  border-block-start-color: var(--colorGrey100);
  border-block-start-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  padding-block-start: 0;
  padding-inline-end: 24px;
  padding-block-end: 0;
  padding-inline-start: 24px;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: flex-start;
    width: unset;
    border: none;
    box-shadow: none;
    background-color: transparent;

    padding-block-start: 48px;
    padding-inline-end: 0;
    padding-block-end: 82px;
    padding-inline-start: 36px;
  }

  @media (--viewportLargeWithPaddings) {
    padding-block-start: 48px;
    padding-inline-end: 0;
    padding-block-end: 82px;
    padding-inline-start: calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-block-start: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-block-start: 24px;
    padding-block-start: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-inline-start: 16px;

  &:first-child {
    margin-inline-start: 0;
  }

  @media (--viewportLarge) {
    margin-inline-start: 0;
  }
}
